import React from "react"

import FormDate from "../../Elements/Form/FormDate"
import FormInput from "../../Elements/Form/FormInput"
import Section from "../../Elements/Section"
import Address from "../../PersonalDetails/Address"
import PersonalInformation from "../PersonalInformation"

import { getSignedInUser } from "../../Auth/services/user"

const PatientPersonalInformation = ({ values, onChange, setFieldValue }) => {
  const addressFieldNames = {
    addressType: `patientAddress.addressType`,
    streetAddress: `patientAddress.streetAddress`,
    city: `patientAddress.city`,
    province: `patientAddress.province`,
    barangay: `patientAddress.barangay`,
  }
  return (
    <Section title="Patient Information" id="patient-information">
      <PersonalInformation
        isEmailDisabled={values?.hasBeenVerified || getSignedInUser()?.email}
        values={values}
      />
      <FormDate
        name="birthday"
        label="Birthday"
        isRequired
        values={values.birthday}
        onChange={onChange}
      />
      <Address
        fieldNames={addressFieldNames}
        values={values.patientAddress}
        isRequired
        setFieldValue={setFieldValue}
        isNationwide
      />
    </Section>
  )
}

export default PatientPersonalInformation
